<template>
    <div class="mt-10">
        <div
            v-editable="blok"
            class="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-wide lg:px-8"
        >
            <div class="flex items-center justify-between space-x-4">
                <h2
                    v-if="blok.title"
                    class="text-2xl font-extrabold tracking-wide"
                >
                    {{ blok.title }}
                </h2>
                <Link
                    :href="`/inventory${queryString}`"
                    class="whitespace-nowrap text-sm font-medium"
                >
                    View all<span aria-hidden="true"> &rarr;</span>
                </Link>
            </div>
            <div
                v-if="vehicles"
                class="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4"
            >
                <div
                    v-for="(vehicle, index) in vehicles"
                    :key="index"
                    class="group relative"
                >
                    <GridVehicle
                        :vehicle="vehicle"
                        :show-history="showHistory"
                        :carfax-data="carfaxData"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import GridVehicle from '@/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicle.vue'
import { getSetting, queryToObject } from '@/utils/functions'
import type { Vehicle } from '~/utils/types/inventory'

const inventoryStore = useInventoryStore()
const domainStore = useDomainStore()
const storyblokStore = useStoryblokStore()

const props = defineProps({
    blok: {
        type: Object as PropType<Record<string, any>>,
        required: true,
    },
})

const vehicles = ref<Vehicle[]>([])
const carfaxData = ref<any[]>([])

const queryString = computed(() => {
    return props.blok.query ? '?' + props.blok.query : ''
})

const showHistory = computed(() => !(props.blok.hide_history ?? false))

onMounted(() => {
    const query = queryToObject(props.blok.query) ?? {}

    setTimeout(() =>
        inventoryStore
            .fetchHomePageInventory(
                Object.assign({}, query, {
                    limit: 4,
                }),
            )
            .then((res) => {
                vehicles.value = res.data.value?.data ?? []

                if (showHistory.value && vehicles.value.length > 0) {
                    const carfaxIsEnabled = getSetting(
                        domainStore.autoZnetworkSettings,
                        'vendor.carfax.enabled',
                    )

                    if (carfaxIsEnabled && storyblokStore.srpHistoryEnabled) {
                        inventoryStore
                            .getCarfaxForVehiclesWithPromise(
                                vehicles.value.map(
                                    (vehicle: Vehicle) => vehicle.id,
                                ),
                            )
                            .then((data: any) => {
                                carfaxData.value = data.data.value?.data ?? []
                            })
                    }
                }
            }),
    )
})
</script>
